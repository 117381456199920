import React, { useEffect } from 'react';
import PrimeReact from 'primereact/api';
import { useRecipeState, selectors, useRecipeDispatch, actions } from './store';
import { useRouteMatch } from 'react-router-dom';
import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';
import Dashboard from './components/Placeholder';
import Login from './components/Login';
import MoreMenu from './components/MoreMenu';
import RecipeEdit from './components/RecipeEdit';
import Search from './components/Search';
import RecipeDetails from './components/RecipeDetails';
import RecipeList from './components/RecipeList';
import TagEditor from './components/TagEditor';
import Overview from './components/Overview';
import SharedTagViewer from './components/SharedTagViewer';
import RouterButton from './components/RouterButton';
import { AuthorizedRoute, PropsRoute } from './util/RouteUtil';

import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './auth/use-auth.js';

export default function App() {
    return (
        <React.Fragment>
            <Header />
            <Main />
            <Footer />
        </React.Fragment>
    );
}

function Main() {
    // active ripple effect
    PrimeReact.ripple = true;
    const auth = useAuth();
    const { isAuthenticated, user } = auth;
    const recipeState = useRecipeState();
    const dispatch = useRecipeDispatch();
    const isLoading = selectors.collectionsLoading(recipeState);
    useEffect(() => {
        if (isAuthenticated) {
            actions.loadTags(dispatch);
        }
    }, [isAuthenticated, dispatch]);

    // maybe some minor cleanup here
    if (user === null) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated && isLoading) {
        return <div>Loading data...</div>;
    }

    return (
        <main className="app">
            <div className="page-container">
                <div>
                    <Route exact path="/">
                        {isAuthenticated ? <Redirect to="/overview" /> : <Redirect to="/login" />}
                    </Route>
                    <AuthorizedRoute path="/overview" component={Overview} />
                    <AuthorizedRoute path="/search/:q" component={RecipeList} />
                    <AuthorizedRoute exact path="/new" component={RecipeEdit} />
                    <AuthorizedRoute path="/edit/:id" component={RecipeEdit} />
                    <AuthorizedRoute path="/dashboard" component={Dashboard} />
                    <AuthorizedRoute path="/view/:id" component={RecipeDetails} />
                    <AuthorizedRoute path="/edit-tags" component={TagEditor} />
                    <PropsRoute path="/login" component={Login} />
                    <PropsRoute path="/share-tag/:token" component={SharedTagViewer} />
                </div>
            </div>
        </main>
    );
}

function Header({ name }) {
    const auth = useAuth();
    const isSharedRoute = useRouteMatch('/share-tag/:token');
    if (isSharedRoute) {
        return <header />;
    }

    const { isAuthenticated } = auth;
    return (
        <header>
            <div>
                <RouterButton
                    link="/overview"
                    icon="pi pi-home"
                    className="p-button-rounded p-button-primary"
                ></RouterButton>
            </div>
            {isAuthenticated ? (
                <React.Fragment>
                    <Search />
                    <div className="app-header-right">
                        <MoreMenu />
                    </div>
                </React.Fragment>
            ) : null}
        </header>
    );
}

// todo what to put here? stats? some kind of plan?
function Footer() {
    const isSharedRoute = useRouteMatch('/share-tag/:token');
    if (isSharedRoute) {
        return <footer />;
    }
    return (
        <footer>
            <p>Recipe database 2.0</p>
        </footer>
    );
}
