import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
// todo since we're currently sending the full recipes back, we could cache them
// and sometimes save the lookup here
import RouterButton from './RouterButton';
import { useRecipeDispatch, useRecipeState, actions } from '../store';
import ReactMarkdown from 'react-markdown';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import './recipe.css';

function calcSourceContent(source = '', url = '') {
    const hasSource = source?.length > 1;
    const hasUrl = url?.length > 1;
    if (!(hasSource || hasUrl)) {
        return null;
    }
    if (hasSource && hasUrl) {
        return (
            <a href={url} target="_blank" rel="noreferrer">
                {source}
            </a>
        );
    }
    //{new URL(source).pathname ? new URL(source).pathname.split('/').pop() : source}
    if (hasSource) {
        return source.startsWith('http') ? (
            <a href={source} target="_blank" rel="noreferrer">
                {new URL(source).host}
            </a>
        ) : (
            <span>{source}</span>
        );
    }
    if (hasUrl) {
        if (url.startsWith('http')) {
            return (
                <a href={url} target="_blank" rel="noreferrer">
                    {new URL(url).host}
                </a>
            );
        }
    }
}

export default function RecipeDetails() {
    const { id } = useParams();
    const dispatch = useRecipeDispatch();
    // todo could do related recipes
    const { selectedRecipe = {} } = useRecipeState();
    // hmm, rethink the way the recipe loading/editing/display works, probably a good
    // bit of dupe code in both
    useLayoutEffect(() => {
        const unsubscribe = () => {};
        dispatch(actions.clearSelectedRecipe());
        if (id) {
            actions.fetchRecipe(dispatch, id);
        }
        return () => unsubscribe();
    }, [id, dispatch]);

    if (!selectedRecipe) {
        return null;
    }

    // todo clean all this up
    const {
        title,
        ingredients,
        instructions,
        notes,
        servings,
        calories,
        image_url,
        source,
        url,
        caloriesPerServing,
    } = selectedRecipe;

    //ingredients = ingredients.replace(/\r\n/g, '  \n');
    //instructions = instructions.replace(/\r\n/g, '  \n');
    //notes = notes.replace(/\r\n/g, '  \n');
    const editRecipeLink = `/edit/${id}`;
    const rightContents = (
        <React.Fragment>
            <RouterButton link={editRecipeLink} icon="pi pi-pencil" className="p-mr-2"></RouterButton>
            <Button icon="pi pi-print" className="p-button-secondary" onClick={() => window.print()} />
        </React.Fragment>
    );
    // const sourceContent = source ? (
    //     source.startsWith('http') ? (
    //         <a href={source} target="_blank" rel="noreferrer">
    //             {new URL(source).pathname ? new URL(source).pathname.split('/').pop() : source}
    //         </a>
    //     ) : (
    //         <span>{source}</span>
    //     )
    // ) : null;
    const sourceContent = calcSourceContent(source, url);
    const RecipeHeader = () => {
        return (
            <div className="recipe-header">
                {image_url && <img alt="" src={image_url} />}
                <div className="recipe-header-main">
                    <div className="recipe-header-title-container">
                        <div>
                            <h2>{title}</h2>
                        </div>
                        <div>{rightContents}</div>
                    </div>
                    <hr />
                    <div className="recipe-header-source">{sourceContent}</div>
                    <div className="recipe-header-details">
                        {!!calories && (
                            <div>
                                <label>calories:</label>
                                {calories}
                            </div>
                        )}
                        {!!servings && (
                            <div>
                                <label>servings:</label>
                                {servings}
                            </div>
                        )}
                        {!!caloriesPerServing && (
                            <div>
                                <label>per serving:</label>
                                {caloriesPerServing}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="recipe-card">
            <RecipeHeader />
            <Panel header="Ingredients" toggleable className="recipe-panel">
                <ReactMarkdown children={ingredients} className="line-break" />
            </Panel>
            <Panel header="Instructions" toggleable className="recipe-panel">
                <ReactMarkdown children={instructions} className="line-break" />
            </Panel>
            <Panel header="Notes" toggleable className="recipe-panel">
                <ReactMarkdown children={notes} className="line-break" />
            </Panel>
        </div>
    );
}
