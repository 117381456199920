import React from 'react';
import { useAuth } from '../auth/use-auth.js';
import { Route, Redirect } from 'react-router-dom';

export function AuthorizedRoute({ component: Component, ...rest }) {
    const auth = useAuth();
    const { isAuthenticated } = auth;
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}
// export const PrivateRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//       Auth.isUserAuthenticated() ? (
//         <Component {...props} {...rest} />
//       ) : (
//         <Redirect to={{
//           pathname: '/',
//           state: { from: props.location }
//         }}/>
//       )
//     )}/>
//   )

export function LoggedOutRoute({ component: Component, ...rest }) {
    const auth = useAuth();
    const { isAuthenticated } = auth;
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location },
                        }}
                    />
                ) : (
                    <Component {...props} {...rest} />
                )
            }
        />
    );
}

// export const LoggedOutRoute = ({ component: Component, ...rest }) => (
//     <Route {...rest} render={props => (
//       Auth.isUserAuthenticated() ? (
//         <Redirect to={{
//           pathname: '/',
//           state: { from: props.location }
//         }}/>
//       ) : (
//         <Component {...props} {...rest} />
//       )
//     )}/>
//   )

export const PropsRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
);
