import React, { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import axios from 'axios';
import unknownFoodImage from '../images/unknown.jpg';
import './ImageField.css';

export default function ImageField({ image_url, image_id, image_type = 'recipe', onUploadComplate = () => {} }) {
    const toast = useRef(null);
    const fileRef = useRef(null);
    const onUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };
    const uploadHandler = async (e) => {
        const data = new FormData();
        data.append('type', image_type);
        data.append('id', image_id);
        data.append('file', e.files[0]);
        const responseData = await axios.post('/upload', data);
        fileRef.current.clear();
        onUploadComplate(responseData);
    };
    return (
        <div className="image-field">
            <Toast ref={toast}></Toast>
            {image_url && <img alt="" src={image_url} onError={(e) => (e.target.src = unknownFoodImage)} />}
            <FileUpload
                ref={fileRef}
                mode="basic"
                name="recipe_image[]"
                url="/upload"
                accept="image/*"
                maxFileSize={1000000}
                onUpload={onUpload}
                customUpload
                uploadHandler={uploadHandler}
                auto
                chooseLabel="Select Image"
                contentClassName="p-button-success"
            />
        </div>
    );
}
