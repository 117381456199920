import * as t from './actionTypes';

export default function recipeReducer(state, action) {
    switch (action.type) {
        case t.SET_SEARCH: {
            return {
                ...state,
                search: action.payload.search,
            };
        }
        case t.SET_RECIPE_RESULTS: {
            return {
                ...state,
                recipeResults: action.payload.data,
            };
        }
        case t.SET_SELECTED_RECIPE: {
            return {
                ...state,
                selectedRecipe: action.payload.recipe,
            };
        }
        case t.CLEAR_SELECTED_RECIPE: {
            return {
                ...state,
                selectedRecipe: null,
            };
        }
        // case t.SET_AUTO_COMPLETE_SEARCH: {
        //     //console.log('action.payload.autoCompleteSearch', action.payload.autoCompleteSearch);
        //     return {
        //         ...state,
        //         autoCompleteSearch: action.payload.autoCompleteSearch,
        //     };
        // }
        // todo maybe this doesn't need to be stored here?
        case t.SET_AUTO_COMPLETE_RESULTS: {
            return {
                ...state,
                autoCompleteResults: action.payload.data,
            };
        }
        case t.SET_TAGS: {
            const { tags, tagSummary } = action.payload;
            return {
                ...state,
                tags,
                tagSummary,
                tagsLoading: false,
            };
        }
        case t.SET_TAGS_LOADING: {
            return {
                ...state,
                tagsLoading: true,
            };
        }
        case t.SET_SHARED_RECIPE_RESULTS: {
            return {
                ...state,
                sharedRecipes: action.payload.data,
            };
        }
        default:
            throw new Error();
    }
}
