import React from 'react';
import './TagList.css';
import { useRecipeState } from '../store';
import RouterButton from './RouterButton';
import unknownFoodImage from '../images/unknown.jpg';

export default function TagList() {
    const recipeState = useRecipeState();
    const { tagSummary: tags } = recipeState;
    return (
        <div className="p-grid tag-list-wrapper">
            {tags && tags.map((tag) => <GridTagItem key={tag.id} tag={tag} />)}
        </div>
    );
}
// import React from 'react';
// import { Card } from 'primereact/card';

// export default function TagSummary({ tag }) {
//     const { id, name, recipe_count } = tag;
//     //const tagLink = `/view/${id}`;

//     const searchText = name.includes(' ') ? `tag:'${name}'` : `tag:${name}`;
//     //const searchText = `${name}`;
//     const searchLink = `/search/${encodeURIComponent(searchText)}`;
//     const footer = null;
//     return (
//         <RouterButton className="card-button" link={searchLink}>
//             <Card title={name} className={'p-shadow-2 base-card p-as-stretch'} footer={footer}>
//                 {name} - {recipe_count} recipes
//             </Card>
//         </RouterButton>
//     );
// }

/*
like this for primeflex 3.0
<div key={key} className="col-12 md:col-4 lg:col-3">
*/
function GridTagItem({ tag }) {
    const { name, recipe_count, image_url, key } = tag;
    const searchText = name.includes(' ') ? `tag:'${name}'` : `tag:${name}`;
    const tagLink = `/search/${encodeURIComponent(searchText)}`;
    const imageSrc = image_url ? image_url : unknownFoodImage;
    return (
        <div key={key} className="p-col-12 p-md-4 p-lg-3">
            <RouterButton className="tag-card-button tag-grid-item" link={tagLink}>
                {/* <div className="tag-grid-item card"> */}
                <div className="tag-grid-item-content">
                    <img src={imageSrc} onError={(e) => (e.target.src = unknownFoodImage)} alt={name} />
                    <div className="tag-name">{name}</div>
                </div>
                <div className="tag-grid-item-bottom">
                    <span className="tag-count">{recipe_count} recipes</span>
                </div>
                {/* </div> */}
            </RouterButton>
        </div>
    );
}
/*
                <div className="tag-grid-item-top">
                    <div>
                        <i className="pi pi-tag tag-category-icon"></i>
                        <span className="tag-category">stuff</span>
                    </div>
                    <span className={`tag-badge`}>status</span>
                </div>
*/
