import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecipeDispatch, useRecipeState, actions } from '../store';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '../App.css';

let selectionKeyPress = false;
function Search() {
    const { autoCompleteResults } = useRecipeState();
    const history = useHistory();
    const match = useRouteMatch('/search/:q');
    let urlQuery;
    if (match) {
        urlQuery = decodeURIComponent(match.params.q);
    }

    const dispatch = useRecipeDispatch();
    const [autoCompleteSearch, setAutoCompleteSearch] = useState(urlQuery);

    function handleChange(e) {
        setAutoCompleteSearch(e.target.value);
    }

    function handleSearchComplete(event) {
        const { query } = event;
        setAutoCompleteSearch(query);
        if (query) {
            actions.autoCompleteRecipes(dispatch, query);
        }
    }

    async function handleFullSearch(e) {
        e.preventDefault();
        if (autoCompleteSearch) {
            const searchLink = `/search/${encodeURIComponent(autoCompleteSearch)}`;
            history.push(searchLink);
        }
    }

    // async function doFullSearch(search) {
    //     if (search) {
    //         actions.searchRecipes(dispatch, search);
    //     }
    // }

    function selectAutoCompleteResult(e) {
        selectionKeyPress = true;
        const { id } = e.value;
        const editRecipeLink = `/view/${id}`;
        history.push(editRecipeLink);
    }

    function handleEnter(e) {
        // selectionKeyPress is necessary to track whether we got here via enter on select
        // list or enter in input. seems like a hack but what are you gonna do?
        if (e.code === 'Enter' && !selectionKeyPress) {
            handleFullSearch(e);
        }
        selectionKeyPress = false;
    }
    const doFullSearch = useCallback(
        async (search) => {
            if (search) {
                actions.searchRecipes(dispatch, search);
            }
        },
        [dispatch]
    );
    useEffect(() => {
        setAutoCompleteSearch(urlQuery);
        doFullSearch(urlQuery);
    }, [urlQuery, doFullSearch]);

    return (
        <div className="search">
            <AutoComplete
                value={autoCompleteSearch}
                suggestions={autoCompleteResults}
                completeMethod={handleSearchComplete}
                field="title"
                onChange={handleChange}
                onSelect={selectAutoCompleteResult}
                onKeyUp={handleEnter}
                //delay={1000}
            />
            <Button onClick={handleFullSearch} icon="pi pi-search" className="p-ml-2 p-button-rounded" />
        </div>
    );
}

export default Search;

// possibly better pattern here: - this works now. now we just need to shift it to thunk and should be good to go
// https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/

// good article about the current impl as well - bonus has debnounce impl without lodash
// https://medium.com/@kartikag01/debounce-api-request-in-react-functional-component-664b4971d9dd

// TODO use this pattern later
// https://gist.github.com/astoilkov/013c513e33fe95fa8846348038d8fe42#gistcomment-3377800

// final go... see these: - do useRecipeDispatch and useRecipeState -- ultimately do bindActions from the 3rd/4th one
// start with async pattern from first, see if that is suffcient
//https://kentcdodds.com/blog/how-to-use-react-context-effectively
//https://codesandbox.io/s/react-codesandbox-je6cc?file=/src/index.js
// https://medium.com/strands-tech-corner/react-state-management-without-redux-d39c7087039d
//https://github.com/pourya7/react-without-redux/tree/master/src/store

// todo would be data.SearchRecipes or just handled via
// another dispatch

// async function sendQuery(value) {
//     const { cancelPrevQuery, result } = await fetchData(value);

//     if (cancelPrevQuery) return;

//     if (result.Response === 'True') {
//         //setDataList(result.Search);
//         //setErrorMssg('');
//     } else {
//         //setDataList([]);
//         //setErrorMssg(result.Error);
//     }
// }

//https://cat-fact.herokuapp.com/facts/random?animal_type=cat&amount=2
//const data = await searchRecipes();
//console.log(data);

// axios({
//     method: 'post',
//     url: '/login',
//     timeout: 4000, // 4 seconds timeout
//     data: {
//         firstName: 'David',
//         lastName: 'Pollock',
//     },
// })
//     .then((response) => {
//         /* handle the response */
//     })
//     .catch((error) => console.error('timeout exceeded'));
//<Button onClick={() => auth.signout()}>Signout</Button>

// import React, { useState, useEffect } from 'react';
// import { CountryService } from '../service/CountryService';

// const AutoCompleteDemo = () => {
//     const [countries, setCountries] = useState([]);
//     const [selectedCountry1, setSelectedCountry1] = useState(null);
//     const [filteredCountries, setFilteredCountries] = useState(null);
//     const countryservice = new CountryService();

//     useEffect(() => {
//         countryservice.getCountries().then((data) => setCountries(data));
//     }, []); // eslint-disable-line react-hooks/exhaustive-deps

//     const searchCountry = (event) => {
//         setTimeout(() => {
//             let _filteredCountries;
//             if (!event.query.trim().length) {
//                 _filteredCountries = [...countries];
//             } else {
//                 _filteredCountries = countries.filter((country) => {
//                     return country.name.toLowerCase().startsWith(event.query.toLowerCase());
//                 });
//             }

//             setFilteredCountries(_filteredCountries);
//         }, 250);
//     };

//     return (
//         <div className="card">
//             <h5>Basic</h5>
//             <AutoComplete
//                 value={selectedCountry1}
//                 suggestions={filteredCountries}
//                 completeMethod={searchCountry}
//                 field="title"
//                 onChange={(e) => setSelectedCountry1(e.value)}
//             />
//         </div>
//     );
// };

// maybe need this.actions or actions.whatever like redux mapstate
// function setAutoCompleteSearch(value) {
//     console.log('value');
//     dispatch(actions.setAutoCompleteSearch({ autoCompleteSearch: value }));
// }
// const debouncedAutoComplete = useCallback(
//     debounce((nextValue) => actions.autoCompleteRecipes(dispatch, nextValue), 1000),
//     [] // will be created only once initially
// );

// function selectAutoCompleteResult(e) {
//     const { id } = e.value;
//     const editRecipeLink = `/edit/${id}`;
//     //e.preventDefault();
//     //history.push(editRecipeLink), [history]);
//     // console.log(e);
//     // console.log(autoCompleteSearch);
//     // if (autoCompleteSearch) {
//     //     // todo combine now?
//     //     const search = autoCompleteSearch;
//     //     //dispatch(actions.setSearch({ search }));
//     //     actions.searchRecipes(dispatch, search);
//     //     toastRef.current.show({ severity: 'info', summary: autoCompleteSearch, life: 3000 });
//     // }
// }

// let location = useLocation();
// useEffect(() => {
//     console.log(location.pathname);
// }, [location]);
//console.log(match);

// useEffect(() => {
//     handleSearchComplete();
// }, [match]);
// const params = useParams();
// console.log(params);
// if (q) {
//     q = decodeURIComponent(q);
//     console.log('match');
// }
