import { createSelector } from 'reselect';
import { validationSchema } from './util';
import unknownFoodImage from '../images/unknown.jpg';
function transformData({ recipe, allTags = [] }) {
    const defaultValues = validationSchema.default();
    if (recipe && recipe.id) {
        // make stupid react happy
        const keysToIgnore = ['tag_ids'];
        for (const [key, value] of Object.entries(recipe)) {
            if (value === null && !keysToIgnore.includes(key)) {
                recipe[key] = '';
            }
        }
        const {
            id,
            title,
            ingredients,
            calories,
            servings,
            image_url,
            url,
            source,
            notes,
            instructions,
            tag_ids,
        } = recipe;

        let tags;
        // == here?
        if (tag_ids && tag_ids.length) {
            tags = tag_ids.map((tid) => allTags.find((t) => tid === Number(t.id)));
        }
        const caloriesPerServing = calories && servings ? Math.round(calories / servings) : '';
        const recipeLink = `/view/${id}`;
        const tagNames = tags?.map((t) => t.name).join(', ');
        let imageSrc = image_url;
        if (!imageSrc) {
            const tagWithImage = tags?.find((t) => t.image_url);
            imageSrc = tagWithImage?.image_url ?? unknownFoodImage;
        }

        return {
            ...defaultValues,
            id,
            title,
            ingredients,
            calories,
            servings,
            image_url,
            url,
            source,
            notes,
            instructions,
            tag_ids,
            tags,
            caloriesPerServing,
            imageSrc,
            tagNames,
            recipeLink,
        };
    } else {
        return defaultValues;
    }
}

export const selectedRecipeSelector = createSelector(
    (appState) => appState.selectedRecipe,
    (appState) => appState.tags,
    (selectedRecipe = {}, allTags) => {
        return transformData({
            recipe: selectedRecipe,
            allTags,
        });
    }
);

export const collectionsLoading = createSelector(
    (appState) => appState,
    (state) => {
        return state?.tagsLoading;
    }
);

export const recipeList = createSelector(
    (appState) => appState.recipeResults,
    (appState) => appState.tags,
    (recipes, allTags) => {
        recipes = recipes || [];
        allTags = allTags || [];
        return recipes.map((recipe) => transformData({ recipe, allTags }));
    }
);

export const sharedRecipeList = createSelector(
    (appState) => appState.sharedRecipes,
    (appState) => appState.tags,
    (recipes, allTags) => {
        recipes = recipes || [];
        allTags = allTags || [];
        return recipes.map((recipe) => transformData({ recipe, allTags }));
    }
);

// function cleanDataForShare({ recipe, allTags = [] }) {
//     const defaultValues = validationSchema.default();

//         return {
//             ...defaultValues,
//             id,
//             title,
//             ingredients,
//             calories,
//             servings,
//             image_url,
//             url,
//             source,
//             notes,
//             instructions,
//             tag_ids,
//             tags,
//             caloriesPerServing,
//             imageSrc,
//             tagNames,
//             recipeLink,
//         };

// }

// export const selectedWineAvailableBottles = createSelector(
//     selectedWineSelector,
//     (appState) => appState.stagedBottlesAddRack,
//     (appState) => appState.stagedBottlesToDrink,
//     (selectedWine, stagedBottlesAddRack, stagedBottlesToDrink) => {
//         if (selectedWine && selectedWine.bottles && selectedWine.bottles.length) {
//             const { bottles, ...wineDetails } = selectedWine;
//             const unPlacedBottles = getUnplacedBottles(bottles);
//             if (stagedBottlesAddRack && stagedBottlesAddRack.length) {
//                 return unPlacedBottles.filter((bottle) => !stagedBottlesAddRack.find((pb) => pb.id === bottle.id));
//             }
//             // add in wineDetails to match the way the rack does it
//             return unPlacedBottles.map((b) => {
//                 b.wineDetails = wineDetails;
//                 if (stagedBottlesToDrink && stagedBottlesToDrink.length) {
//                     b.stagedForDrinking = stagedBottlesToDrink.some((sb) => sb.id === b.id);
//                 } else {
//                     b.stagedForDrinking = false;
//                 }
//                 b.isOfSelectedWine = true;
//                 return b;
//             });
//         }
//         return [];
//     }
// );

// function getUnplacedBottles(bottles) {
//     bottles = bottles || [];
//     const unPlacedBottles = bottles && bottles.filter((b) => b.colidx == null || b.rowidx == null);
//     return unPlacedBottles;
// }

// export const bottlesInRack = createSelector(
//     (appState) => appState.wines,
//     (appState) => appState.producers,
//     (appState) => appState.varietals,
//     (appState) => appState.types,
//     (appState) => appState.tags,
//     (wines, producers, varietals, types, tags) => {
//         wines = wines || [];
//         return wines.reduce((rackdata, wine) => {
//             const wineTransformed = transformData({
//                 wine,
//                 producers,
//                 varietals,
//                 types,
//                 tags,
//                 defaultValues: defaultInitialValues,
//             });
//             const { bottles, ...wineDetails } = wineTransformed;

//             if (bottles && bottles.length) {
//                 const bottlesInRack = bottles.filter((b) => b.colidx != null && b.rowidx != null);
//                 const bottlesWithData = bottlesInRack.map((b) => {
//                     b.wineDetails = wineDetails;
//                     // todo: hack for now
//                     b.rackId = 1;
//                     return b;
//                 });
//                 rackdata.push(...bottlesWithData);
//             }

//             return rackdata;
//         }, []);
//     }
// );

// export const bottlesInRackWithStaged = createSelector(
//     bottlesInRack,
//     selectedWineSelector,
//     (appState) => appState.stagedBottlesAddRack,
//     (appState) => appState.stagedBottlesRemoveRack,
//     (appState) => appState.stagedBottlesToDrink,
//     (bottlesSavedInRack, selectedWine, stagedBottlesAddRack, stagedBottlesRemoveRack, stagedBottlesToDrink) => {
//         // first reset all the bools
//         const bottles = bottlesSavedInRack.map((b) => {
//             b.stagedForDrinking = false;
//             b.stagedForAdd = false;
//             b.stagedForRemove = false;
//             return b;
//         });

//         // then mark any that are staged for removal
//         if (stagedBottlesRemoveRack && stagedBottlesRemoveRack.length) {
//             stagedBottlesRemoveRack.forEach((sb) => {
//                 const bottleToUpdate = bottles.find((b) => b.colidx === sb.colidx && b.rowidx === sb.rowidx);
//                 bottleToUpdate.stagedForRemove = true;
//             });
//         }
//         // then mark any staged for drinking
//         if (stagedBottlesToDrink && stagedBottlesToDrink.length) {
//             stagedBottlesToDrink.forEach((sbd) => {
//                 const bottleToUpdate = bottles.find((b) => b.colidx === sbd.colidx && b.rowidx === sbd.rowidx);
//                 if (bottleToUpdate) {
//                     bottleToUpdate.stagedForDrinking = true;
//                 }
//             });
//         }

//         // finally add in any that are staged for adding
//         if (stagedBottlesAddRack && stagedBottlesAddRack.length) {
//             stagedBottlesAddRack.forEach((b) => {
//                 b.stagedForAdd = true;
//                 bottles.push(b);
//             });
//         }

//         return bottles.map((b) => {
//             const isOfSelectedWine = selectedWine && selectedWine.id === b.wineDetails.id;
//             b.isOfSelectedWine = isOfSelectedWine;
//             return b;
//         });
//     }
// );

// export const selectedFilterTags = createSelector(
//     collectionsLoading,
//     (appState) => appState.tags,
//     (appState) => appState.filters,
//     (loading, allTags, filters) => {
//         if (loading || !allTags) {
//             return [];
//         }
//         const { tags = [] } = filters;
//         return allTags.filter((t) => tags.includes(Number(t.value)));
//     }
// );

// export const unSelectedFilterTags = createSelector(
//     collectionsLoading,
//     (appState) => appState.tags,
//     (appState) => appState.filters,
//     (loading, allTags, filters) => {
//         if (loading || !allTags) {
//             return [];
//         }
//         const { tags = [] } = filters;
//         return allTags.filter((t) => !tags.includes(Number(t.value)));
//     }
// );

// export const selectedFilterVarietals = createSelector(
//     collectionsLoading,
//     (appState) => appState.varietals,
//     (appState) => appState.filters,
//     (loading, allVarietals, filters) => {
//         if (loading || !allVarietals) {
//             return [];
//         }
//         const { varietals = [] } = filters;
//         return allVarietals.filter((v) => varietals.includes(Number(v.value)));
//     }
// );

// export const unSelectedFilterVarietals = createSelector(
//     collectionsLoading,
//     (appState) => appState.varietals,
//     (appState) => appState.filters,
//     (loading, allVarietals, filters) => {
//         if (loading || !allVarietals) {
//             return [];
//         }
//         const { varietals = [] } = filters;
//         return allVarietals.filter((v) => !varietals.includes(Number(v.value)));
//     }
// );

// export const selectedFilterTypes = createSelector(
//     collectionsLoading,
//     (appState) => appState.types,
//     (appState) => appState.filters,
//     (loading, allTypes, filters) => {
//         if (loading || !allTypes) {
//             return [];
//         }
//         const { types = [] } = filters;
//         return allTypes.filter((v) => types.includes(Number(v.value)));
//     }
// );

// export const unSelectedFilterTypes = createSelector(
//     collectionsLoading,
//     (appState) => appState.types,
//     (appState) => appState.filters,
//     (loading, allTypes, filters) => {
//         if (loading || !allTypes) {
//             return [];
//         }
//         const { types = [] } = filters;
//         return allTypes.filter((v) => !types.includes(Number(v.value)));
//     }
// );

// export const winesWithFiltersAndMeta = createSelector(
//     winesWithFilters,
//     (appState) => appState.producers,
//     (appState) => appState.varietals,
//     (appState) => appState.types,
//     (appState) => appState.tags,
//     (filteredWines, producers = [], varietals = [], types = [], tags = []) => {
//         if (!filteredWines) {
//             return [];
//         }
//         const winesWithMeta = filteredWines.map((wine, idx) => {
//             // first do basic transform
//             const transformed = transformData({
//                 wine,
//                 producers,
//                 varietals,
//                 types,
//                 tags,
//                 //defaultValues: defaultInitialValues
//             });

//             transformed.imageData = getImageForWine(wine);
//             transformed.tagLabels = getTagLabels(wine, tags);
//             // todo figure out some neat ways to do featured
//             // one way might be with num bottles
//             transformed.featureTall = Math.random() <= 0.25;
//             transformed.featureShort = Math.random() <= 0.25;
//             return transformed;
//         });
//         return shuffle(winesWithMeta);
//     }
// );

// function getTagLabels(w, tags = []) {
//     const { tag_ids } = w;
//     const hasTags = tag_ids && tag_ids.length;

//     let tagLabels = '';
//     if (hasTags) {
//         tagLabels = tag_ids
//             .map((id) => {
//                 let tagObj = tags.find((t) => Number(t.value) == id);
//                 return tagObj.label;
//             })
//             .join(', ');
//     }
//     return tagLabels;
// }
