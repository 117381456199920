import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button } from 'primereact/button';
import { Redirect } from 'react-router-dom';

import { useAuth } from '../auth/use-auth.js';

export default function Login(props) {
    const auth = useAuth();
    const { isAuthenticated } = auth;
    return (
        <div>
            <h5>Welcome to Recipe DB 2.0</h5>
            {!isAuthenticated ? (
                <GoogleLogin
                    clientId="832414497302-jhoj6ng1ftmil32r1843u1itemjr88ri.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={auth.googleResponse}
                    onFailure={auth.googleResponse}
                    render={(renderProps) => (
                        <Button color="primary" onClick={renderProps.onClick} variant="outlined">
                            Login
                        </Button>
                    )}
                />
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            )}
        </div>
    );
}
