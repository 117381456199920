import React, { useState, useEffect, useRef } from 'react';
import { useRecipeDispatch, useRecipeState, actions } from '../store';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import ImageField from './ImageField';
import { CopyButton } from '../util/CopyButton';
import './TagEditor.css';

export default function TagEditor() {
    const dispatch = useRecipeDispatch();
    const recipeState = useRecipeState();
    const { tags } = recipeState;

    const [workingTags, setWorkingTags] = useState(null);
    const [editingCellRows, setEditingCellRows] = useState([]);
    const toastRef = useRef(null);
    useEffect(() => {
        setWorkingTags(tags);
    }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log(toastRef.current);
    }, [toastRef]);

    const onEditorValueChange = (props, value) => {
        let updatedTags = [...props.value];
        updatedTags[props.rowIndex][props.field] = value;
        setWorkingTags(updatedTags);
    };

    const onEditorCancel = (e) => {
        const { rowIndex: index, field } = e.columnProps;
        const _tags = [...workingTags];
        const _editingCellRows = [...editingCellRows];
        _tags[index][field] = _editingCellRows[index][field];
        delete _editingCellRows[index][field];
        setEditingCellRows(_editingCellRows);
        setWorkingTags(_tags);
    };

    const onEditorSubmit = async (e) => {
        const { rowIndex: index, field } = e.columnProps;
        const _editingCellRows = [...editingCellRows];
        const updatedTag = workingTags[index];
        delete _editingCellRows[index][field];
        setEditingCellRows(_editingCellRows);

        // todo show error if fail
        const updated = await actions.updateTag(dispatch, updatedTag);
        if (updated) {
            // this would work except I reload the tags to the page renders,
            // no biggie, but we could make it work through store if we wanted
            console.log('success toast', updated);
            // const toastMsg = `Updated: ${updatedTag.name}`;
            // toastRef.current.show({ severity: 'success', summary: toastMsg, life: 2000 });
        }
    };

    const inputTextEditor = (props, field) => {
        return (
            <InputText
                type="text"
                value={props.rowData[field]}
                onChange={(e) => onEditorValueChange(props, e.target.value)}
            />
        );
    };

    const onEditorInit = (e) => {
        const { rowIndex: index, field, rowData } = e.columnProps;
        let _editingCellRows = [...editingCellRows];
        if (!editingCellRows[index]) {
            _editingCellRows[index] = { ...rowData };
        }
        _editingCellRows[index][field] = workingTags[index][field];
        setEditingCellRows(_editingCellRows);
    };

    // const emptyValueValidator = (e) => {
    //     const { rowData, field } = e.columnProps;
    //     return rowData[field].trim().length > 0;
    // };

    const nameEditor = (props) => {
        return inputTextEditor(props, 'name');
    };

    const actionsTemplate = (rowData) => {
        const { id, token } = rowData;
        const accept = async () => {
            await actions.deleteTag(dispatch, id);
            //toastRef.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Delete Tag', life: 3000 });
        };

        const reject = () => {
            toastRef.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have declined', life: 3000 });
        };

        const confirmDelTag = () => {
            confirmDialog({
                message: 'Do you want to delete this record? ' + id,
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept,
                reject,
            });
        };

        const genShareToken = async () => {
            await actions.generateShareToken(dispatch, id);
        };

        const rmShareToken = async () => {
            await actions.revokeShareToken(dispatch, id);
        };

        //p-button-text for text
        return (
            <div className="tag-editor-actions">
                <Button onClick={confirmDelTag} className="p-button-outlined" icon="pi pi-trash" label="Delete Tag" />
                {!!token && <Button onClick={rmShareToken} className="p-button-outlined" label="Revoke Share Token" />}
                {!token && <Button onClick={genShareToken} className="p-button-outlined" label="Gen Share Token" />}
            </div>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <ImageField
                image_id={rowData.id}
                image_type="tag"
                image_url={rowData.image_url}
                className="tag-editor-image"
                onUploadComplate={(e) => {
                    actions.loadTags(dispatch);
                }}
            />
        );
    };

    const tokenBodyTemplate = ({ token }) => {
        if (token) {
            const urlObj = new URL(window.location);
            urlObj.pathname = `/share-tag/${token}`;
            const url = urlObj.toString();
            return (
                <CopyButton
                    buttonLabel={token}
                    text={url}
                    onUploadComplate={(e) => {
                        actions.loadTags(dispatch);
                    }}
                />
            );
        }
    };

    return (
        <div className="tag-editor">
            <Toast ref={toastRef} />
            <h2>Edit Tags</h2>
            <DataTable value={workingTags} editMode="cell" className="editable-cells-table">
                <Column header="Image" body={imageBodyTemplate} style={{ width: 200 }}></Column>
                <Column
                    field="name"
                    header="Name"
                    editor={nameEditor}
                    onEditorCancel={onEditorCancel}
                    onEditorSubmit={onEditorSubmit}
                    onEditorInit={onEditorInit}
                ></Column>
                <Column field="token" header="Token" body={tokenBodyTemplate} style={{ width: 300 }}></Column>
                <Column field="actions" header="actions" body={actionsTemplate} style={{ width: 250 }}></Column>
                {/* <Column
                        field="inventoryStatus"
                        header="Status"
                        body={statusBodyTemplate}
                        editor={(props) => statusEditor('products1', props)}
                    ></Column>
                    <Column
                        field="price"
                        header="Price"
                        body={priceBodyTemplate}
                        editor={(props) => priceEditor('products1', props)}
                    ></Column> */}
            </DataTable>

            {/* <div className="card">
                <h5>Advanced Cell Editing</h5>
                <p>Custom implementation with validations, dynamic columns and reverting values with the escape key.</p>
                <DataTable value={products2} editMode="cell" className="editable-cells-table">
                    {columns.map((col) => {
                        const { field, header } = col;
                        const validator =
                            field === 'quantity' || field === 'price' ? positiveIntegerValidator : emptyValueValidator;
                        return (
                            <Column
                                key={field}
                                field={field}
                                header={header}
                                body={field === 'price' && priceBodyTemplate}
                                editor={(props) => inputTextEditor('products2', props, field)}
                                editorValidator={validator}
                                onEditorInit={onEditorInit}
                                onEditorCancel={onEditorCancel}
                                onEditorSubmit={onEditorSubmit}
                            />
                        );
                    })}
                </DataTable>
            </div> */}
        </div>
    );
}
