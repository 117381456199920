import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { useRecipeDispatch, useRecipeState, actions, util, selectors } from '../store';
import ValidatingInputField from './ValidatingInputField';
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import MarkdownField from './MarkdownField';
import ImageField from './ImageField';
import './recipe.css';

const { validationSchema } = util;
const defaultState = validationSchema.default();

function shouldUpdateCollection(origCol, newCol) {
    const isOrigArray = Array.isArray(origCol);
    const isNewArray = Array.isArray(newCol);
    // if neither is an array then don't update;
    if (!isOrigArray && !isNewArray) {
        return false;
    }
    if (!isOrigArray && !newCol?.length) {
        return false;
    }

    if (!isNewArray && !origCol?.length) {
        return false;
    }

    if (origCol?.length !== newCol?.length) {
        // if diff len then we should update
        return true;
    }
    // if same len and any items are diff we should update
    const allSame = origCol.every((id) => newCol.includes(id));
    return !allSame;
}

export default function RecipeEdit() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    const toastRef = useRef();
    const { id } = useParams();
    const dispatch = useRecipeDispatch();
    const recipeState = useRecipeState();
    const { tags } = recipeState;
    const selectedRecipe = selectors.selectedRecipeSelector(recipeState);
    const [filteredTags, setFilteredTags] = useState([]);

    const isEditMode = !!id;
    const action = isEditMode ? 'Edit' : 'Add';
    const buttonText = isEditMode ? 'Save' : 'Add';
    const toastMsg = `${buttonText} successful!`;
    const [values, setValues] = useState(defaultState);

    function handleInputChange(e) {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }
    const history = useHistory();
    async function save(e) {
        e.preventDefault();

        //const isValid = await validationSchema.validate(values);
        validationSchema.validate(values, { abortEarly: false }).catch((err) => {
            //console.log(err.inner, err.errors, err.path);
            //console.log(err.inner);
            const { inner } = err;
            if (inner.length) {
                inner.forEach((valErr) => {
                    console.log(valErr.path, valErr.message);
                });
            }
        });
        const preppedValues = validationSchema.cast(values);
        // prep tags and tags
        // TODO only send if length is different or not all ids match
        // (i.e. only send if these have changed)
        const { tags } = preppedValues;
        const { tag_ids } = selectedRecipe;
        const newTag_ids = tags?.length ? tags.map((o) => o.id) : [];
        // null these out if they're the same so we don't unnecessarly update (use empty array for "none")
        preppedValues.tag_ids = shouldUpdateCollection(tag_ids, newTag_ids) ? newTag_ids : null;
        if (isEditMode) {
            const updated = await actions.saveRecipe(dispatch, preppedValues);
            if (updated) {
                //console.log('success, todo toast', toastMsg);
                toastRef.current.show({ severity: 'success', summary: toastMsg, life: 2000 });
            }
        } else {
            // addnew
            // save and then redirect to the view version of this new recipe with it's id
            const updated = await actions.saveRecipe(dispatch, preppedValues);
            if (updated?.id) {
                const recipeLink = `/view/${updated.id}`;
                history.push(recipeLink);
            }
        }
    }

    useEffect(() => {
        if (id) {
            actions.clearSelectedRecipe(dispatch);
            actions.fetchRecipe(dispatch, id);
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (selectedRecipe?.id) {
            setValues(selectedRecipe);
        } else {
            setValues(defaultState);
        }
    }, [selectedRecipe, id]);

    const searchCollection = (event, setMethod, fullCollection) => {
        setTimeout(() => {
            let _filteredCollection;
            if (!event.query.trim().length) {
                _filteredCollection = [...fullCollection];
            } else {
                _filteredCollection = fullCollection.filter((item) => {
                    return item.name.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            setMethod(_filteredCollection);
        }, 50);
    };

    function setSelectedTags(tags) {
        setValues({ ...values, tags });
    }

    const formTitle = `${action} Recipe`;
    const vewRecipeLink = `/view/${id}`;
    const subTitle = isEditMode ? (
        <Link to={vewRecipeLink} className="primary">
            View Recipe
        </Link>
    ) : null;

    const onUploadComplate = (fileName) => {
        setValues({ ...values, image_url: fileName });
    };

    // useEffect(() => {
    //     if (selectedRecipe?.id) {
    //         setValues(selectedRecipe);
    //     } else {
    //         setValues(defaultState);
    //     }
    // }, [values.image_url]);

    return (
        <Card title={formTitle} subTitle={subTitle} className="p-shadow-2" style={{ margin: '2em' }}>
            <Toast ref={toastRef} />
            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col-12 p-md-8">
                    <ValidatingInputField label={'Title'} schema={validationSchema} fieldKey="title" values={values}>
                        <InputText
                            type="text"
                            name="title"
                            onChange={handleInputChange}
                            value={values.title}
                            autoComplete="off"
                        />
                    </ValidatingInputField>
                </div>
                {isEditMode && (
                    <div className="p-field p-col-12 p-md-4">
                        <ImageField
                            key={Date.now()}
                            image_id={values.id}
                            image_url={values.image_url}
                            onUploadComplate={({ data }) => onUploadComplate(data)}
                        />
                    </div>
                )}
                <div className="p-field p-col-12 p-md-4">
                    <ValidatingInputField
                        label={'Calories Total'}
                        schema={validationSchema}
                        fieldKey="calories"
                        values={values}
                    >
                        <InputText
                            type="text"
                            name="calories"
                            onChange={handleInputChange}
                            value={values.calories}
                            autoComplete="off"
                        />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <ValidatingInputField
                        label={'Servings'}
                        schema={validationSchema}
                        fieldKey="servings"
                        values={values}
                    >
                        <InputText
                            type="text"
                            name="servings"
                            onChange={handleInputChange}
                            value={values.servings}
                            autoComplete="off"
                        />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12 p-md-4">
                    <ValidatingInputField label={'Source'} schema={validationSchema} fieldKey="source" values={values}>
                        <InputText
                            type="text"
                            name="source"
                            onChange={handleInputChange}
                            value={values.source}
                            autoComplete="off"
                        />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12 p-md-6">
                    <label>{'Tags'}</label>
                    <AutoComplete
                        value={values.tags}
                        suggestions={filteredTags}
                        completeMethod={(e) => searchCollection(e, setFilteredTags, tags)}
                        field="name"
                        multiple
                        dropdown
                        onChange={(e) => setSelectedTags(e.value)}
                    />
                </div>

                <div className="p-field p-col-12 p-md-12">
                    <ValidatingInputField label={'Url'} schema={validationSchema} fieldKey="url" values={values}>
                        <InputText
                            type="text"
                            name="url"
                            onChange={handleInputChange}
                            value={values.url}
                            autoComplete="off"
                        />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12">
                    <ValidatingInputField
                        label={'Ingredients'}
                        schema={validationSchema}
                        fieldKey="ingredients"
                        values={values}
                        markDownMode
                    >
                        <MarkdownField name="ingredients" onChange={handleInputChange} value={values.ingredients} />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12">
                    <ValidatingInputField
                        label={'Instructions'}
                        schema={validationSchema}
                        fieldKey="instructions"
                        values={values}
                        markDownMode
                    >
                        <MarkdownField name="instructions" onChange={handleInputChange} value={values.instructions} />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12">
                    <ValidatingInputField
                        label={'Notes'}
                        schema={validationSchema}
                        fieldKey="notes"
                        values={values}
                        markDownMode
                    >
                        <MarkdownField name="notes" onChange={handleInputChange} value={values.notes} />
                    </ValidatingInputField>
                </div>
                <div className="p-field p-col-12">
                    <Button label={buttonText} className="p-button-outlined" onClick={save} />
                </div>
            </div>
        </Card>
    );
}
/*
            <Fieldset legend="Ingredients" className="field-set">
                <ReactMarkdown className="line-break" children={ingredients} />
            </Fieldset>
            <Fieldset legend="Instructions" toggleable className="field-set">
                <ReactMarkdown className="line-break" children={instructions} />
            </Fieldset>
            <Fieldset legend="Notes" toggleable className="field-set">
                <ReactMarkdown className="line-break" children={notes} />
            </Fieldset>
*/
