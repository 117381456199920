import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import { RecipeProvider } from './store/store';
import { ProvideAuth } from './auth//use-auth.js';

ReactDOM.render(
    <ProvideAuth>
        <Router>
            <React.StrictMode>
                <RecipeProvider>
                    {/* <div className="app-background"></div> */}
                    <App />
                </RecipeProvider>
            </React.StrictMode>
        </Router>
    </ProvideAuth>,
    document.getElementById('root')
);
