import React, { useState } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import ReactMarkdown from 'react-markdown';
import RouterButton from './RouterButton';
import { Link } from 'react-router-dom';
import unknownFoodImage from '../images/unknown.jpg';
import { useRecipeState, selectors } from '../store';

import { Card } from 'primereact/card';
//import classnames from 'classnames';
// const classes = classnames('p-shadow-2', styles);
import './RecipeList.css';
import './RecipeSummary.css';

export default function RecipeSummary({ recipe, layout = 'summary' }) {
    const { id, title, ingredients } = recipe;

    if (!recipe) {
        return;
    }

    if (layout === 'list') {
        return renderListItem(recipe);
    }
    if (layout === 'grid') {
        return renderGridItem(recipe);
    }
    if (layout === 'summary') {
        return renderSummaryItem(recipe);
    }
}

// todo position image on left, title on right, ingredients on right, then instructions/notes on bottom
const renderSummaryItem = ({
    ingredients,
    title,
    caloriesPerServing,
    imageSrc,
    tagNames,
    recipeLink,
    instructions,
}) => {
    return (
        <div className="recipe-summary">
            <div className="recipe-upper-container">
                <div className="image-frame">
                    <div className="recipe-name">{title}</div>
                    <img src={imageSrc} onError={(e) => (e.target.src = unknownFoodImage)} alt={title} />
                </div>
                {ingredients && (
                    <div className="recipe-list-detail">
                        <div className="recipe-description">
                            <h3>Ingredients</h3>
                            <ReactMarkdown className="markdown-summary line-break" children={ingredients} />
                        </div>
                    </div>
                )}
            </div>
            {instructions && (
                <div className="recipe-list-detail">
                    <div className="recipe-description">
                        <h3>Directions</h3>
                        <ReactMarkdown className="markdown-summary line-break" children={instructions} />
                    </div>
                    <span className="recipe-tags">{tagNames}</span>
                </div>
            )}
            <div className="nutrition">
                <span>{caloriesPerServing && caloriesPerServing + ' cal'}</span>
            </div>
        </div>
    );
};

const renderListItem = ({ id, ingredients, title, tags, caloriesPerServing, imageSrc, tagNames, recipeLink }) => {
    return (
        <div className="p-col-12">
            <div className="recipe-list-item">
                <img src={imageSrc} onError={(e) => (e.target.src = unknownFoodImage)} alt={title} />
                <div className="recipe-list-detail">
                    <div className="recipe-name">
                        <Link to={recipeLink} className="primary">
                            {title}
                        </Link>
                    </div>
                    <div className="recipe-description">
                        <ReactMarkdown className="markdown-summary line-break line-clamp-4" children={ingredients} />
                    </div>
                    <i className="pi pi-tag recipe-category-icon"></i>
                    <span className="recipe-category">{tagNames}</span>
                </div>
                <div className="recipe-list-action">
                    <span className="recipe-price">{caloriesPerServing && caloriesPerServing + ' cal'}</span>
                    <RouterButton link={recipeLink} icon="pi pi-book" label="View Details"></RouterButton>
                </div>
            </div>
        </div>
    );
};

const renderGridItem = ({ ingredients, title, caloriesPerServing, imageSrc, tagNames, recipeLink }) => {
    return (
        <div className="p-col-12 p-md-4 p-align-stretch recipe-grid-item-wrapper">
            <div className="recipe-grid-item">
                <div className="recipe-grid-item-top">
                    <div>
                        <i className="pi pi-tag recipe-category-icon"></i>
                        <span className="recipe-category">{tagNames}</span>
                    </div>
                    <span className={`recipe-badge`}></span>
                </div>
                <div className="recipe-grid-item-content">
                    <img src={imageSrc} onError={(e) => (e.target.src = unknownFoodImage)} alt={title} />
                    <div className="recipe-name">
                        <Link to={recipeLink} className="primary">
                            {title}
                        </Link>
                    </div>
                    <div className="recipe-description">
                        <ReactMarkdown className="markdown-summary line-break line-clamp-4" children={ingredients} />
                    </div>
                </div>
                <div className="recipe-grid-item-bottom">
                    <span className="recipe-price">{caloriesPerServing && caloriesPerServing + ' cal'}</span>
                    <RouterButton link={recipeLink} icon="pi pi-book" label="View Details"></RouterButton>
                </div>
            </div>
        </div>
    );
};

//const source = ingredients.replace(/\r\n/g, '  \n');
// may be som other ways to achieve the proper line breaks
// https://github.com/remarkjs/react-markdown/issues/273#issuecomment-683754992

// return (
//     <RouterButton className="card-button" link={recipeLink}>
//         <Card title={title} className={'p-shadow-2 base-card p-as-stretch'} footer={footer}>
//             <ReactMarkdown
//                 className="markdown-summary line-break"
//                 children={ingredients}
//                 // remarkPlugins={[remarkBreaks]}
//             />
//         </Card>
//     </RouterButton>
// );
