import * as Yup from 'yup';
// const validationSchema = Yup.object().shape({
//     name: Yup.string().required('Wine name is required.'),
//     producer: Yup.object().nullable().required('Producer is required.'),
//     year: Yup.number()
//         .required('Year is required.')
//         .integer('Year must be an integer')
//         .min(1900, 'Must be greater than 1900')
//         .max(3000, 'Must be less than 3000.'),
//     cost: Yup.number().nullable(),
//     retail: Yup.number().nullable(),
//     tags: Yup.array().nullable(),
//     image_url: Yup.string().nullable(),
//     description: Yup.string().nullable(),
//     bottleCount: Yup.number().nullable().integer('bottleCount must be an integer'),
// });
// helper for yup transform function
function emptyStringToNull(value, originalValue) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}

// to validate a number with min and max and allowing empty string
//const mySchema = yup.number().min(-0.2).max(0.2).transform(emptyStringToNull).nullable();

export const validationSchema = Yup.object({
    title: Yup.string().required('Title is required.').default(''),
    ingredients: Yup.string().required('Ingredients are required.').default(''),
    calories: Yup.number().transform(emptyStringToNull).nullable().default(''),
    servings: Yup.number().transform(emptyStringToNull).nullable().default(''),
    image_url: Yup.string().transform(emptyStringToNull).nullable().default(''),
    url: Yup.string().transform(emptyStringToNull).nullable().default(''),
    source: Yup.string().transform(emptyStringToNull).nullable().default(''),
    notes: Yup.string().transform(emptyStringToNull).nullable().default(''),
    instructions: Yup.string().transform(emptyStringToNull).nullable().default(''),
    tags: Yup.array().nullable(),
    tag_ids: Yup.array().nullable(),
});

// function transformFormFieldsForFullSave(values) {
//     // note here we want to always send tags b/c empty array means empty on server
//     const { cost, retail, producer, type, varietal, ...rest } = values;
//     const tags = values.tags || [];
//     const payload = {
//         ...rest,
//         fk_producer: producer ? Number(producer.value) : null,
//         fk_type: type ? Number(type.value) : null,
//         fk_varietal: varietal ? Number(varietal.value) : null,
//         tags: tags.map((t) => Number(t.value)),
//         cost: cost ? Number(cost) : null,
//         retail: retail ? Number(retail) : null,
//     };
//     return payload;
// }
