import { makeActionCreator } from './helpers';
import * as t from './actionTypes';
//import { useRecipeDispatch } from './store';
import axios from 'axios';
//export const setAutoCompleteSearch = makeActionCreator(t.SET_AUTO_COMPLETE_SEARCH, 'autoCompleteSearch');
export const setSearch = makeActionCreator(t.SET_SEARCH, 'search');
export const setAutoCompleteResults = makeActionCreator(t.SET_AUTO_COMPLETE_RESULTS, 'data');
export const setRecipeResults = makeActionCreator(t.SET_RECIPE_RESULTS, 'data');
export const setSelectedRecipe = makeActionCreator(t.SET_SELECTED_RECIPE, 'recipe');
export const setTags = makeActionCreator(t.SET_TAGS, 'tags', 'tagSummary');
export const setTagsLoading = makeActionCreator(t.SET_TAGS_LOADING);
export const clearSelectedRecipe = makeActionCreator(t.CLEAR_SELECTED_RECIPE);
export const setSharedRecipeResults = makeActionCreator(t.SET_SHARED_RECIPE_RESULTS, 'data');

async function searchRecipesAPI(searchText) {
    //const url = `https://cat-fact.herokuapp.com/facts/random?animal_type=cat&amount=2`;
    //const
    const url = `/api/v1/search?q=${encodeURIComponent(searchText)}`;
    const res = await axios.get(url);
    const data = await res.data;
    //console.log('got the data', data);
    return data;
}

// todo tweak this api call to only get titles/ids
async function searchAutoCompleteAPI(searchText) {
    //const url = `https://cat-fact.herokuapp.com/facts/random?animal_type=cat&amount=2`;
    const url = `/api/v1/autocomplete?q=${searchText}`;
    const res = await axios.get(url);
    const data = await res.data;
    //console.log('got autocomplete data', data);
    return data;
}

export async function fetchRecipe(dispatch, recipeId) {
    try {
        const url = `/api/v1/get`;
        const res = await axios.post(url, { id: recipeId });
        const recipe = await res.data;
        dispatch(setSelectedRecipe({ recipe }));
    } catch (error) {
        dispatch(setSelectedRecipe({ recipe: null }));
    }
}

export async function saveRecipe(dispatch, updatedRecipe) {
    try {
        const url = `/api/v1/save`;
        const res = await axios.post(url, updatedRecipe);
        const recipe = await res.data;
        dispatch(setSelectedRecipe({ recipe }));
        return recipe;
    } catch (error) {
        dispatch(setSelectedRecipe({ recipe: null }));
        return false;
    }
}

export async function searchRecipes(dispatch, value) {
    dispatch(setSearch({ search: value }));
    // could do start api call type thing
    //dispatch({type: 'start update', updates})
    try {
        const recipes = await searchRecipesAPI(value);
        dispatch(setRecipeResults({ data: recipes }));
    } catch (error) {
        dispatch(setRecipeResults({ data: null }));
    }
}

// todo use different call here, only return id/title
export async function autoCompleteRecipes(dispatch, value) {
    //dispatch(setAutoCompleteSearch({ autoCompleteSearch: value }));
    try {
        const recipes = await searchAutoCompleteAPI(value);
        dispatch(setAutoCompleteResults({ data: recipes }));
    } catch (error) {
        dispatch(setAutoCompleteResults({ data: null }));
    }
}

export async function loadTags(dispatch) {
    try {
        dispatch(setTagsLoading());
        const url = `/api/v1/tags`;
        const urlSummary = `/api/v1/tag-summary`;
        const [tags, tagSummary] = await Promise.all([
            axios.get(url).then((res) => res.data),
            axios.get(urlSummary).then((res) => res.data),
        ]);
        dispatch(setTags({ tags, tagSummary }));
    } catch (error) {
        dispatch(setTags({ tags: null, tagSummary: null }));
    }
}

export async function updateTag(dispatch, tagData) {
    try {
        const url = `/api/v1/update-tag`;
        const res = await axios.post(url, tagData);
        const updatedTag = await res.data;
        await loadTags(dispatch);
        return updatedTag;
    } catch (error) {
        console.error(error);
    }
}
export async function deleteTag(dispatch, tagId) {
    try {
        const url = `/api/v1/delete-tag`;
        const res = await axios.post(url, { id: tagId });
        await res.data;
        await loadTags(dispatch);
    } catch (error) {
        console.error(error);
    }
}

export async function generateShareToken(dispatch, tagId) {
    try {
        const url = `/api/v1/generate-share-token`;
        const res = await axios.post(url, { id: tagId });
        await res.data;
        await loadTags(dispatch);
    } catch (error) {
        console.error(error);
    }
}

export async function revokeShareToken(dispatch, tagId) {
    try {
        const url = `/api/v1/remove-share-token`;
        const res = await axios.post(url, { id: tagId });
        await res.data;
        await loadTags(dispatch);
    } catch (error) {
        console.error(error);
    }
}

export async function fetchSharedhRecipeData(dispatch, token, type) {
    try {
        const url = `/api/v1/fetch-shared`;
        const res = await axios.post(url, { token, type });
        const data = await res.data;
        // .. if type = tag else single recipe
        dispatch(setSharedRecipeResults({ data }));
    } catch (error) {
        console.error(error);
    }
}

//   // deleteFile is an action with a thunk instead of an object
//   function deleteFile(file) {
//     return (dispatch) => {
//         unlink(file, () => dispatch({ type: "DELETE_FILE_SUCCESS" });
//      }
//   }

//   function App() {
//     const [state, dispatch] = useReducer((state, action) => {
//       switch (action.type) {
//         case 'DELETE_FILE_SUCCESS':
//             // Do your thing to your state after deletion
//           };
//       }
//     }, {
//       files: ['a', 'b', 'c']
//     });

//     return (
//       <DispatchContext.Provider value={withThunk(dispatch)}>
//         <Component files={state.files} />
//       </DispatchContext.Provider>
//     );
//   }

//   function Component({ files }) {
//     const dispatch = useContext(DispatchContext);

//     return (
//       <>
//         {files.map(file =>
//           // dispatch the action thunk
//           <button onClick={dispatch(deleteFile(file))}>Delete File</button>
//         )}
//       </>
//     );
//   }
