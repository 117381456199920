import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useAuth } from '../auth/use-auth.js';

export default function MoreMenu() {
    const menu = useRef(null);
    const auth = useAuth();
    const items = [
        {
            label: 'New Recipe',
            icon: 'pi pi-plus',
            command: (event) => {
                window.location = '/new';
            },
        },
        {
            label: 'Edit Tags',
            icon: 'pi pi-tags',
            command: (event) => {
                window.location = '/edit-tags';
            },
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: auth.logout,
        },
    ];

    return (
        <React.Fragment>
            <Menu model={items} popup ref={menu} id="popup_menu" />
            <Button
                icon="pi pi-ellipsis-v"
                className="p-button-rounded p-button-secondary "
                onClick={(event) => menu.current.toggle(event)}
                aria-controls="popup_menu"
                aria-haspopup
            />
        </React.Fragment>
    );
}
