import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useRecipeDispatch, useRecipeState, actions, selectors } from '../store';
import { Card } from 'primereact/card';
import ReactMarkdown from 'react-markdown';
import './RecipeList.css';
import { Link } from 'react-router-dom';
import unknownFoodImage from '../images/unknown.jpg';
import RecipeSummary from './RecipeSummary';

export default function SharedTagViewer() {
    const recipeState = useRecipeState();
    const { token } = useParams();
    const dispatch = useRecipeDispatch();
    //let urlQuery;

    const getSharedRecipeData = useCallback(
        async (token) => {
            if (token) {
                actions.fetchSharedhRecipeData(dispatch, token, 'tag');
            }
        },
        [dispatch]
    );
    useEffect(() => {
        if (token) {
            getSharedRecipeData(token);
        }
    }, [token]);
    const recipes = selectors.sharedRecipeList(recipeState);
    return (
        <div className="p-grid">
            {recipes &&
                recipes.map((recipe) => (
                    <div key={recipe.id} className="p-d-flex p-col-12 p-lg-6">
                        <RecipeSummary recipe={recipe} layout="summary" />
                    </div>
                ))}
        </div>
    );
}
