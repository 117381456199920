import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

export default function RouterButton({ size, children, link, label, className, icon }) {
    const history = useHistory();
    const handleOnClick = useCallback(() => history.push(link), [history, link]);
    return (
        <Button label={label} icon={icon} className={className} size={size} onClick={handleOnClick}>
            {children}
        </Button>
    );
}
