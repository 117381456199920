// store.js

import React, { createContext, useReducer } from 'react';

import recipeReducer from './reducer';
//import { pluralizedCollectionNames, isLoadingCollectionNames } from './constants';
//import { formIsLoadingCollectionName, formPluralizedCollectionName } from '../util/helpers';

//todo: change, this will set it true for dev, need to fix it properly
//const defaultAuthState = process.env.NODE_ENV === 'development';

const INITIAL_STATE = {
    search: '',
    tagsLoading: true,
    tags: null,
    tagSummary: null,
    sharedRecipes: null,
    //autoCompleteSearch: '',
};

// const store = createContext(INITIAL_STATE);

// const { Provider } = store;

// const StateProvider = ({ children }) => {
//     const [appState, dispatch] = useReducer((state, action) => {
//         switch (action.type) {
//             case t.SET_SEARCH: {
//                 return {
//                     ...state,
//                     search: action.payload.search,
//                 };
//             }
//             default:
//                 throw new Error();
//         }
//     }, INITIAL_STATE);

//     return <Provider value={{ appState, dispatch }}>{children}</Provider>;
// };

// export { store, StateProvider };

const RecipeStateContext = createContext(INITIAL_STATE);
const RecipeDispatchContext = createContext(INITIAL_STATE);

function RecipeProvider({ children }) {
    const [state, dispatch] = useReducer(recipeReducer, INITIAL_STATE);
    return (
        <RecipeStateContext.Provider value={state}>
            <RecipeDispatchContext.Provider value={dispatch}>{children}</RecipeDispatchContext.Provider>
        </RecipeStateContext.Provider>
    );
}

function useRecipeState() {
    const context = React.useContext(RecipeStateContext);
    if (context === undefined) {
        throw new Error('useRecipeState must be used within a RecipeProvider');
    }
    return context;
}

function useRecipeDispatch() {
    const context = React.useContext(RecipeDispatchContext);
    if (context === undefined) {
        throw new Error('useRecipeDispatch must be used within a RecipeProvider');
    }
    return context;
}

export { RecipeProvider, useRecipeState, useRecipeDispatch };
