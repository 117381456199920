// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext, useCallback } from 'react';

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    //const history = useHistory();
    const configUser = useCallback(
        (data) => {
            if (data?.loggedin) {
                setIsAuthenticated(true);
            }
            setUser(data);
        },
        [setUser, setIsAuthenticated]
    );

    const googleResponse = (response) => {
        //...
        const tokenBlob = new Blob([JSON.stringify({ access_token: response.accessToken }, null, 2)], {
            type: 'application/json',
        });
        const options = {
            method: 'POST',
            body: tokenBlob,
            mode: 'cors',
            cache: 'default',
            credentials: 'include',
        };
        fetch('/api/v1/auth/google', options)
            .then((r) => r.json())
            .then(configUser);
    };

    const checkUser = useCallback(() => {
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
            credentials: 'include',
        };
        const url = '/api/v1/userinfo';
        return fetch(url, options)
            .then((r) => r.json())
            .then(configUser);
    }, [configUser]);

    // const checkUser = () => {
    //     const options = {
    //         method: 'GET',
    //         mode: 'cors',
    //         cache: 'default',
    //         credentials: 'include',
    //     };
    //     const url = '/api/v1/userinfo';
    //     return fetch(url, options)
    //         .then((r) => r.json())
    //         .then(configUser);
    // };

    const logout = () => {
        const options = {
            method: 'GET',
            mode: 'cors',
            cache: 'default',
            credentials: 'include',
        };
        const url = '/api/v1/logout';
        fetch(url, options).then(() => {
            configUser({});
        });
    };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    // todo something like this in real life
    useEffect(() => {
        const unsubscribe = () => {
            //checkUser();
        };
        // todo see Search doFullSearch for how to clear the eslint warning
        checkUser();
        // console.log('effecto user check');
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [checkUser]);

    // Return the user object and auth methods
    return {
        user,
        isAuthenticated,
        logout,
        //login,
        googleResponse,
        //sendPasswordResetEmail,
        //confirmPasswordReset,
    };
}
