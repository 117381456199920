import React, { useState, useEffect } from 'react';
import short from 'short-uuid';
const INVALID_CLASS = 'p-invalid';
export default function ValidatingInputField({ schema, fieldKey, values, children, label, markDownMode }) {
    // function handleChange(value) {
    //     onChange({ target: { name, value } });
    // }
    const [touched, setTouched] = useState(false);
    const [inputId, setInputId] = useState(null);
    const [validationErrMsg, setValidationErrMsg] = useState(null);
    const [isInValid, setIsInValid] = useState(false);
    function handleBlur(e) {
        setTouched(true);
    }
    const modifiedChildren = React.Children.map(children, (child) => {
        const classes = child.props.className || '';
        const childProps = {
            id: inputId,
            onBlur: handleBlur,
        };
        if (isInValid) {
            if (markDownMode) {
                childProps.reactMde = { 'p-inputtext': true, 'p-component': true, 'p-invalid': true };
            } else {
                childProps.classes = `${classes} ${INVALID_CLASS}`;
            }
        }
        return React.cloneElement(child, childProps);
    });

    useEffect(() => {
        if (!inputId) {
            setInputId(short.generate());
        }
    }, [setInputId, inputId]);

    useEffect(() => {
        if (touched) {
            async function validationCheck() {
                let isValid = true;
                let msg = null;
                await schema.validateAt(fieldKey, values).catch((err) => {
                    isValid = false;
                    if (err?.errors?.length) {
                        msg = err.errors[0];
                    }
                });
                setIsInValid(!isValid);
                setValidationErrMsg(msg);
            }
            validationCheck();
        }
    }, [touched, schema, fieldKey, values]);
    // function modifyChildren(child) {
    //     const className = classnames(child.props.className, { ...otherClassses });

    //     const props = {
    //         className,
    //     };

    //     return React.cloneElement(child, props);
    // }
    //    console.log({ touched, inputId });

    return (
        <React.Fragment>
            <label htmlFor={inputId}>{label}</label>
            {modifiedChildren}
            {isInValid && <small className="p-error p-d-block">{validationErrMsg}</small>}
        </React.Fragment>
    );
}
