import React, { useState } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import ReactMarkdown from 'react-markdown';
import './RecipeList.css';
import RouterButton from './RouterButton';
import { Link } from 'react-router-dom';
import unknownFoodImage from '../images/unknown.jpg';
import RecipeSummary from './RecipeSummary';
import { useRecipeState, selectors } from '../store';

export default function RecipeList() {
    const recipeState = useRecipeState();
    const recipes = selectors.recipeList(recipeState);
    const [layout, setLayout] = useState('list');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const sortOptions = [
        { label: 'Title descending', value: '!title' },
        { label: 'Title ascending', value: 'title' },
        { label: 'Calories descending', value: '!calories' },
        { label: 'Calories ascending', value: 'calories' },
    ];

    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };

    const itemTemplate = (recipe, layout) => <RecipeSummary recipe={recipe} layout={layout}></RecipeSummary>;

    const renderHeader = () => {
        return (
            <div className="p-grid">
                <div className="p-col-6" style={{ textAlign: 'left' }}>
                    <Dropdown
                        options={sortOptions}
                        value={sortKey}
                        optionLabel="label"
                        placeholder="Sort By Title"
                        onChange={onSortChange}
                    />
                </div>
                <div className="p-col-6" style={{ textAlign: 'right' }}>
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <div className="dataview-recipes">
            <div className="card">
                <DataView
                    value={recipes}
                    layout={layout}
                    header={header}
                    itemTemplate={itemTemplate}
                    paginator
                    rows={500}
                    sortOrder={sortOrder}
                    sortField={sortField}
                />
            </div>
        </div>
    );
}
