import React, { useState } from 'react';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
// add passthrough classes for ReactMda (reactMdeClasses)
export default function MarkdownField({ value, className, onChange, onBlur, error, touched, name, reactMde }) {
    function handleChange(value) {
        onChange({ target: { name, value } });
    }

    // function handleBlur() {
    //     onBlur(name, true);
    // }
    //{ reactMde: { 'p-inputtext': true, 'p-component': true, 'p-invalid': true } }
    const classes = reactMde ? { reactMde } : null;
    const [selectedTab, setSelectedTab] = useState('write');

    return (
        <div className={className}>
            {/* <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">
                Markdown Cheatsheet
            </a> */}
            <ReactMde
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                childProps={{
                    textArea: {
                        onBlur: onBlur,
                    },
                }}
                selectedTab={selectedTab}
                classes={classes}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown source={markdown} />)}
            />

            {!!error && touched && <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>}
        </div>
    );
}
