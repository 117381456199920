import { Button } from 'primereact/button';

export function CopyButton({ buttonLabel, text, onCopyComplete = (t) => console.log(t) }) {
    const copyToClipboard = async () => {
        try {
            const permissions = await navigator.permissions.query({ name: 'clipboard-write' });
            if (permissions.state === 'granted' || permissions.state === 'prompt') {
                await navigator.clipboard.writeText(text);
                onCopyComplete(text);
            } else {
                throw new Error("Can't access the clipboard. Check your browser permissions.");
            }
        } catch (error) {
            alert('Error copying to clipboard:', error);
        }
    };

    return <Button onClick={copyToClipboard} label={buttonLabel} className="p-button-outlined" icon="pi pi-copy" />;
}
